import { Cache } from 'aws-amplify'
import { Link, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Seo from '../components/util/Seo'

const ErrorPage: React.FC = ({ location }: any) => {
  const isAccountMaintenance = process.env.GATSBY_ENABLE_MAINTENANCE_MODE_ACCOUNT === 'true' ? true : false

  useEffect(() => {
    if (!isAccountMaintenance) {
      const lastAccountRoute = Cache.getItem('lastAccountRoute')
      navigate(lastAccountRoute ?? '/account')
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta http-equiv="refresh" content="60" />
      </Helmet>
      <StaticImage
        className="mb-2 w-full"
        src="../images/maintenance-mode-header.png"
        alt="We're currently doing some maintenance."
      />
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-center leading-tight">Website Under Planned Maintenance</h1>
          <div className="py-16">
            <div className="text-center">
              <p className="mt-2 mb-4 tracking-tight md:text-2xl">
                We will be doing scheduled maintenance on our website between 8:30AM-1PM Wednesday 7th December. <br />
                Please try logging in again later. <br />
                <br />
                If you have any questions please contact our Customer Delight team at{' '}
                <Link className="underline" to="mailto:feedme@differentdog.com">
                  feedme@differentdog.com
                </Link>{' '}
                or on <a href="tel:+441743384562"> 01743 384 562.</a>
              </p>
              <p className="mb-4">We're sorry for any inconvenience caused and thank you for your patience.</p>
              <a href="/" className="text-base font-medium text-primary hover:underline">
                <span aria-hidden="true"> &larr;</span> Go back to Homepage
              </a>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default ErrorPage
